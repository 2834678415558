"use client";

import { FC } from "react";

// Next
import Link from "next/link";
import Image from "next/image";
import { useLocale, useTranslations } from "next-intl";

// Components
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../Tooltip";

// Utils
import { getFQCN } from "@/utils/functions/fqcn";
import { IFQCN_BUI } from "@/utils/types/common";

type Props = {
  fqcn_bui: IFQCN_BUI;
};

const Logo: FC<Props> = ({ fqcn_bui }) => {
  const locale = useLocale();

  const t = useTranslations("Logo");

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Link id={getFQCN(fqcn_bui, "link-logo")} href={`/${locale}`}>
            <div className="bg-white border border-gray-300 rounded pt-2 scale-80 shadow m-3 pb-2 w-28 dark:bg-dark-mode">
              <div className="flex place-content-center">
                <Image
                  id={getFQCN(fqcn_bui, "light-logo")}
                  src={"/image.webp"}
                  width={80}
                  height={30}
                  alt="phpcreation logo"
                  className="block dark:hidden"
                />

                <Image
                  id={getFQCN(fqcn_bui, "dark-logo")}
                  src={"/logo-darkmode.png"}
                  width={80}
                  height={30}
                  alt="phpcreation logo"
                  className="hidden dark:block"
                />
              </div>
            </div>
          </Link>
        </TooltipTrigger>

        <TooltipContent>
          <p>{t("Home")}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default Logo;
