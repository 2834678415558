"use client";

import { useEffect, useState } from "react";

export default function Clock() {
  const [displayDate, setDisplayDate] = useState<string>("");
  const [displayTime, setDisplayTime] = useState<string>("");
  // const [intervalID, setIntervalID] = useState<NodeJS.Timeout | undefined>(
  //   undefined
  // );

  const date = new Date();

  function handleDisplayTime() {
    return (
      date.getHours().toString().padStart(2, "0") +
      ":" +
      date.getMinutes().toString().padStart(2, "0")
      // + ":" +
      // date.getSeconds().toString().padStart(2, "0")
    );
  }

  function handleDisplayDate() {
    return (
      date.getFullYear() +
      "/" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      date.getDate().toString().padStart(2, "0")
    );
  }

  useEffect(() => {
    setDisplayTime(handleDisplayTime());
    setDisplayDate(handleDisplayDate());
    // setIntervalID(
    //   setInterval(() => {
    //     setDisplayTime(handleDisplayTime());
    //   }, 1000)
    // );

    // return () => {
    //   if (intervalID) {
    //     clearInterval(intervalID);
    //   }
    // };
  }, [date]);

  return (
    <div className="grid grid-rows-2 text-center">
      <p>{displayTime}</p>
      <p>{displayDate}</p>
    </div>
  );
}
